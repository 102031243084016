import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";
import "./Campaigns.css";

import { API, Auth } from "aws-amplify";
import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import EditCampaign from "./EditCampaign";
import IosFunnel from "react-ionicons/lib/IosFunnel";
import Loading from "./Loading";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdCreate from "react-ionicons/lib/MdCreate";
import NewCampaign from "./NewCampaign";
import ViewCampaign from "./ViewCampaign";

export default function Campaigns(props) {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  const [campaigns, setCampaigns] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewNewCampaignModel, setViewNewCampaignModel] = useState(false);
  const [viewEditModal, setViewEditModal] = useState(false);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [outreachTotal, setOutreachTotal] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        const campaigns = await loadCampaigns();
        const accounts = await loadAccounts();
        const templates = await loadTemplates();
        const user = await Auth.currentUserInfo();
        console.log(user);
        setIsAdmin(user && user.attributes.email === "lukasz@planswell.com");
        setTemplates(templates);
        setCampaigns(
          campaigns.filter((campaign) => campaign.accountId === props.accountId)
        );
        setOutreachTotal(
          campaigns
            .filter(
              (campaign) =>
                campaign.accountId === props.accountId &&
                campaign.paused === false
            )
            .reduce((acc, obj) => {
              return acc + obj.dailyLimit;
            }, 0)
        );
        setAccounts(accounts);
        setAccount(
          accounts.filter((account) => account.accountId === props.accountId)[0]
        );
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.history, props.thisUser, props.accountId]);

  function loadTemplates() {
    return API.get("templates", "/templates");
  }

  function loadCampaigns() {
    return API.post("campaigns", "/admincampaigns", {
      body: {
        userId: "us-east-1:5f20c719-1f01-445e-b9e1-f1cb34d1e58b",
      },
    });
  }

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:5f20c719-1f01-445e-b9e1-f1cb34d1e58b",
      },
    });
  }

  function showEditModal(campaignId) {
    setCampaignId(campaignId);
    setViewEditModal(true);
  }

  function showViewCampaignModal(campaignId) {
    setCampaignId(campaignId);
    setViewCampaignModal(true);
  }

  function renderCampaigns() {
    return (
      <div className="Campaigns">
        {accounts.length > 0 ? (
          <Row>
            <Col sm={12}>
              {campaigns.length > 0 ? (
                <div>
                  <Row>
                    <Col xs={4}>
                      <div className="titlePanel">
                        <span
                          className="other-btn"
                          onClick={() => setViewNewCampaignModel(true)}
                        >
                          <MdAdd fontSize="16px" />
                          New Campaign
                        </span>
                      </div>
                    </Col>
                  </Row>

                  {outreachTotal >= 100 ? (
                    <div className="warning-label">
                      <p>
                        We recommend you send out less than 100 connection
                        requests a day
                      </p>
                    </div>
                  ) : null}

                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Sending Limit</th>
                        <th>Contacts</th>
                        <th>Requests</th>
                        <th>Connections</th>
                        <th>Replies</th>
                        <th>Positive Replies</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campaigns.map((campaign, i) => (
                        <tr key={campaign.campaignId}>
                          {isAdmin ? (
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                showViewCampaignModal(campaign.campaignId)
                              }
                            >
                              {campaign.title}
                            </td>
                          ) : (
                            <td>{campaign.title}</td>
                          )}
                          <td>
                            {campaign.dailyLimit}{" "}
                            {isAdmin ? (
                              <span
                                onClick={() =>
                                  showEditModal(campaign.campaignId)
                                }
                                className="other-btn"
                              >
                                <MdCreate fontSize="14px" />
                                Update
                              </span>
                            ) : null}
                          </td>
                          <td>{campaign.contacts}</td>
                          <td>
                            {campaign.requests}{" "}
                            {campaign.contacts > 0 ? (
                              <small className="blue">
                                {parseInt(
                                  (campaign.requests / campaign.contacts) * 100
                                )}
                                %
                              </small>
                            ) : null}
                          </td>
                          <td>
                            {campaign.connections}{" "}
                            {campaign.contacts > 0 ? (
                              <small className="blue">
                                {parseInt(
                                  (campaign.connections / campaign.contacts) *
                                    100
                                )}
                                %
                              </small>
                            ) : null}
                          </td>
                          <td>
                            {campaign.conversations}{" "}
                            {campaign.contacts > 0 ? (
                              <small className="blue">
                                {parseInt(
                                  (campaign.conversations / campaign.contacts) *
                                    100
                                )}
                                %
                              </small>
                            ) : null}
                          </td>
                          <td>
                            {campaign.positives}{" "}
                            {campaign.contacts > 0 ? (
                              <small className="blue">
                                {parseInt(
                                  (campaign.positives / campaign.contacts) * 100
                                )}
                                %
                              </small>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : templates.length > 0 ? (
                <div>
                  {isAdmin ? (
                    <div className="text-center">
                      <br />
                      <br />
                      <br />
                      <br />
                      <p className="text-center">
                        <IosFunnel fontSize="64px" color="#8792a2" />
                      </p>
                      <p>
                        Create your first LinkedIn outreach campaign for{" "}
                        {account.firstName} {account.lastName}'s account
                      </p>
                      <p className="text-center">
                        <button
                          onClick={() => setViewNewCampaignModel(true)}
                          className="btn-secondary"
                        >
                          New Campaign
                        </button>
                      </p>
                    </div>
                  ) : (
                    <div className="text-center">
                      <br />
                      <br />
                      <br />
                      <br />
                      <p className="text-center">
                        <IosFunnel fontSize="64px" color="#8792a2" />
                      </p>
                      <p>Your first campaign hasn't been created yet.</p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center">
                  <br />
                  <br />
                  <br />
                  <br />
                  <p className="text-center">
                    <IosFunnel fontSize="64px" color="#8792a2" />
                  </p>
                  <p>You don't have access</p>
                </div>
              )}
            </Col>
          </Row>
        ) : (
          props.history.push("/accounts/new")
        )}

        <Modal
          show={viewEditModal}
          onHide={() => setViewEditModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <EditCampaign campaignId={campaignId} props={props} />
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={viewNewCampaignModel} dialogClassName="modal-90w">
          <Modal.Body>
            <div className="modal-card text-center">
              <button
                onClick={() => setViewNewCampaignModel(false)}
                className="other-btn"
              >
                close
              </button>
              <NewCampaign
                setScreen={props.setScreen}
                props={props}
                accountId={props.accountId}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={viewCampaignModal} dialogClassName="modal-90w">
          <Modal.Body>
            <div className="modal-card text-center">
              <button
                onClick={() => setViewCampaignModal(false)}
                className="other-btn"
              >
                close
              </button>
              <br />
              <br />
              <ViewCampaign
                changeScreen={props.changeScreen}
                props={props}
                campaignId={campaignId}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return <div className="">{isLoading ? <Loading /> : renderCampaigns()}</div>;
}
