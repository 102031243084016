import "./Accounts.css";

import { API, Auth } from "aws-amplify";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import IosRefresh from "react-ionicons/lib/IosRefresh";

export default function Dashboard(props) {
  const [noAccount, setNoAccount] = useState(false);

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }
    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const accounts = await loadAccounts();
        const profile = await loadProfile(user["id"]);
        let userEmail = profile.email;
        console.log(userEmail);
        const accountFromEmail = accounts.filter(
          (account) => account.email === userEmail
        );

        console.log(accounts);

        if (userEmail === "lukasz@planswell.com") {
          props.history.push(`/accounts/0dd15800-e7bd-11ea-b002-13fb53ee5cfb`);
        } else {
          if (accounts.length === 0) {
            props.history.push(`/accounts/new`);
          } else {
            if (accountFromEmail.length > 0) {
              props.history.push(`/accounts/${accountFromEmail[0].accountId}`);
            } else {
              setNoAccount(true);
            }
          }
        }
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.history]);

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:5f20c719-1f01-445e-b9e1-f1cb34d1e58b",
      },
    });
  }

  return (
    <div>
      <br />
      <br />
      {noAccount ? (
        <div className="form-wrapper">
          <Row>
            <Col className="text-center">
              <p style={{ opacity: 1, textAlign: "center" }}>
                <b>
                  {" "}
                  <span role="img" aria-label="sad">
                    😞
                  </span>{" "}
                  Hmm ...{" "}
                </b>
              </p>
              <p>
                Looks like there's no LinkedIn account associated with the email
                you signed up with.
              </p>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="form-wrapper">
          <Row>
            <Col className="text-center">
              <p style={{ opacity: 1, textAlign: "center" }}>
                <b> Welcome! </b>
              </p>

              <p>We are loading your account.</p>
            </Col>
          </Row>
          <br />
          <p className="text-center">
            <IosRefresh fontSize="60px" color="#0176ff" rotate={true} />
          </p>
        </div>
      )}
    </div>
  );
}
