import "./Accounts.css";

import { API, Auth } from "aws-amplify";
import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import Broadcast from "./Broadcast";
import Campaigns from "./Campaigns";
import Contacts from "./Contacts";
import Conversations from "./Conversations";
import IosApps from "react-ionicons/lib/IosApps";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosChatboxes from "react-ionicons/lib/IosChatboxes";
import IosFunnel from "react-ionicons/lib/IosFunnel";
import MdMedal from "react-ionicons/lib/MdMedal";
import IosPaper from "react-ionicons/lib/IosPaper";
import IosSettings from "react-ionicons/lib/IosSettings";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdContacts from "react-ionicons/lib/MdContacts";
import MdSchool from "react-ionicons/lib/MdSchool";
import NewAccount from "./NewAccount";
import Settings from "./Settings";
import Templates from "./Templates";
import ViewCampaign from "./ViewCampaign";

export default function Accounts(props) {
  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAccountList, setAccountLimit] = useState(false);
  const [screen, setScreen] = useState("home");
  const [viewAddAaccountModal, setViewAddAccountModal] = useState(false);
  const [viewPaymentModal, setViewPaymentModal] = useState(false);
  const [viewCrmModal, setViewCrmModal] = useState(false);
  const [viewReferFriend, setViewReferFriend] = useState(false);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        const accounts = await loadAccounts();
        const campaigns = await loadCampaigns();
        const user = await Auth.currentUserInfo();
        console.log(user);
        setIsAdmin(user && user.attributes.email === "lukasz@planswell.com");
        if (!(user && user.attributes.email === "lukasz@planswell.com"))
          setScreen("campaigns");
        const account = accounts.filter(
          (account) => account.accountId === props.accountId
        )[0];
        setCampaigns(campaigns);
        setAccount(account);
        setAccounts(accounts);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.accountId]);

  function loadCampaigns() {
    return API.post("campaigns", "/admincampaigns", {
      body: {
        userId: "us-east-1:5f20c719-1f01-445e-b9e1-f1cb34d1e58b",
      },
    });
  }

  function loadAccounts() {
    return API.post("accounts", "/adminaccounts", {
      body: {
        userId: "us-east-1:5f20c719-1f01-445e-b9e1-f1cb34d1e58b",
      },
    });
  }

  function accountsOverlay() {
    setAccountLimit(true);
  }

  function changeScreen(newScreen) {
    setViewPaymentModal(false);
    setViewAddAccountModal(false);
    setScreen(newScreen);
  }

  function renderAccounts() {
    return (
      <div className="Accounts">
        <Row>
          <Col sm={2}>
            {!isLoading ? (
              <div>
                {isAdmin ? (
                  <div className="accountsColumn">
                    <h3 onClick={() => accountsOverlay()}>
                      <span className="circle">
                        <IosApps fontSize="16px" />
                      </span>
                      {account.firstName} {account.lastName}{" "}
                      <IosArrowDown fontSize="16px" />
                    </h3>

                    {showAccountList ? (
                      <div
                        className="accountsOverlay"
                        onMouseLeave={() => setAccountLimit(false)}
                      >
                        {accounts
                          .filter((account) => account.firstName === "none")
                          .map((account) => (
                            <a
                              style={{ textDecoration: "none" }}
                              href={`/accounts/${account.accountId}`}
                              key={account.accountId}
                            >
                              <h3>
                                <span className="circle">
                                  <IosApps fontSize="16px" />
                                </span>
                                Unverified
                              </h3>
                            </a>
                          ))}
                        {accounts
                          .filter((account) => account.firstName !== "none")
                          .map((account) => (
                            <a
                              style={{ textDecoration: "none" }}
                              href={`/accounts/${account.accountId}`}
                              key={account.accountId}
                            >
                              <h3>
                                <span className="circle">
                                  <IosApps fontSize="16px" />
                                </span>
                                {account.firstName} {account.lastName}{" "}
                              </h3>
                            </a>
                          ))}
                        <h3 onClick={() => setViewAddAccountModal(true)}>
                          <span className="circle">
                            <MdAdd fontSize="16px" />
                          </span>
                          Add Account
                        </h3>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <img
                    height="35"
                    alt="conversify logo"
                    src={props.props.adminLogo}
                  />
                )}
              </div>
            ) : null}
            <br />
            {isAdmin ? (
              <div>
                <h4
                  onClick={() => setScreen("templates")}
                  className={screen === "templates" ? "active" : null}
                >
                  <IosPaper
                    fontSize="16px"
                    color={screen === "templates" ? "#0176ff" : null}
                  />
                  Templates
                </h4>
                <h4
                  onClick={() => setScreen("contacts")}
                  className={screen === "contacts" ? "active" : null}
                >
                  <MdContacts
                    fontSize="16px"
                    color={screen === "contacts" ? "#0176ff" : null}
                  />
                  CSV Export
                </h4>
                <h4
                  onClick={() => setScreen("campaigns")}
                  className={screen === "campaigns" ? "active" : null}
                >
                  <IosFunnel
                    fontSize="16px"
                    color={screen === "campaigns" ? "#0176ff" : null}
                  />
                  Campaigns
                </h4>
              </div>
            ) : null}
            <h4
              onClick={() => setScreen("home")}
              className={screen === "home" ? "active" : null}
              style={{ marginBottom: "0px" }}
            >
              <MdMedal
                fontSize="16px"
                color={screen === "home" ? "#0176ff" : null}
              />
              Leaderboard
            </h4>

            <h4
              onClick={() => setScreen("conversations")}
              className={screen === "conversations" ? "active" : null}
            >
              <IosChatboxes
                fontSize="16px"
                color={screen === "conversations" ? "#0176ff" : null}
              />
              Inbox
            </h4>
            <br />
            {isAdmin ? (
              <h4
                onClick={() => setScreen("settings")}
                className={screen === "settings" ? "active" : null}
                style={{ marginTop: "0px" }}
              >
                <IosSettings
                  fontSize="16px"
                  color={screen === "settings" ? "#0176ff" : null}
                />
                Settings
              </h4>
            ) : (
              <h4 onClick={() => props.props.handleLogout()}>
                <IosArrowForward fontSize="16px" /> Logout
              </h4>
            )}
          </Col>
          <Col sm={10}>
            <div className="cardComponent">
              {screen === "home" && account ? (
                campaigns.length > 0 ? (
                  <div>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Account</th>
                          <th>Sending Limit</th>
                          <th>Contacts</th>
                          <th>Requests</th>
                          <th>Connections</th>
                          <th>Replies</th>
                          <th>Positive Replies</th>
                        </tr>
                      </thead>
                      <tbody>
                        {campaigns
                          .sort((a, b) => b.positives - a.positives)
                          .map((campaign, i) => (
                            <tr key={campaign.campaignId}>
                              <td>{campaign.title}</td>
                              <td>{campaign.username}</td>
                              <td>{campaign.dailyLimit} </td>
                              <td>{campaign.contacts}</td>
                              <td>
                                {campaign.requests}{" "}
                                {campaign.contacts > 0 &&
                                campaign.requests > 0 ? (
                                  <small className="blue">
                                    {parseInt(
                                      (campaign.requests / campaign.contacts) *
                                        100
                                    )}
                                    %
                                  </small>
                                ) : null}
                              </td>
                              <td>
                                {campaign.connections}{" "}
                                {campaign.contacts > 0 &&
                                campaign.connections > 0 ? (
                                  <small className="blue">
                                    {parseInt(
                                      (campaign.connections /
                                        campaign.contacts) *
                                        100
                                    )}
                                    %
                                  </small>
                                ) : null}
                              </td>
                              <td>
                                {campaign.conversations}{" "}
                                {campaign.contacts > 0 &&
                                campaign.conversations ? (
                                  <small className="blue">
                                    {parseInt(
                                      (campaign.conversations /
                                        campaign.contacts) *
                                        100
                                    )}
                                    %
                                  </small>
                                ) : null}
                              </td>
                              <td>
                                {campaign.positives}{" "}
                                {campaign.contacts > 0 &&
                                campaign.positives > 0 ? (
                                  <small className="blue">
                                    {parseInt(
                                      (campaign.positives / campaign.contacts) *
                                        100
                                    )}
                                    %
                                  </small>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <Row>
                    <Col sm={12}>
                      <div className="home">
                        <h2>
                          Follow these steps to get started with{" "}
                          {account.firstName} {account.lastName}'s account
                        </h2>
                        <div
                          onClick={() => setScreen("templates")}
                          className="step"
                          style={{ borderTop: "1px solid #bdc3c7" }}
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#0176ff" />{" "}
                            Create a templated sequence of LinkedIn messages
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("contacts")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#0176ff" />{" "}
                            Generate a CSV of contacts from a Sales Nav list
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("campaigns")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#0176ff" />{" "}
                            Start a campaign using your template and CSV of
                            contacts
                          </p>
                        </div>
                        <div
                          onClick={() => setScreen("conversations")}
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#0176ff" />{" "}
                            Manage conversations from {account.firstName}{" "}
                            {account.lastName}'s account
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )
              ) : null}
              {screen === "contacts" ? (
                <Contacts props={props} accountId={props.accountId} />
              ) : null}
              {screen === "campaigns" ? (
                <Campaigns
                  changeScreen={changeScreen}
                  props={props}
                  accountId={props.accountId}
                  isAdmin={isAdmin}
                />
              ) : null}
              {screen === "conversations" ? (
                <Conversations
                  campaigns={campaigns}
                  props={props}
                  accountId={props.accountId}
                />
              ) : null}
              {screen === "broadcast" ? (
                <Broadcast
                  campaigns={campaigns}
                  props={props}
                  accountId={props.accountId}
                />
              ) : null}

              {screen === "intelligence" ? (
                <div className="text-center">
                  <br />
                  <br />
                  <br />
                  <br />
                  <p className="text-center">
                    <MdSchool fontSize="64px" color="#8792a2" />
                  </p>
                  <p>Learn how to get the most out of Umeetly</p>
                  <p className="text-center">
                    <a
                      href="https://www.youtube.com/playlist?list=PLCnQAqDAT-SiXU13Fg1M8VaP1Ha7Q3niP"
                      alt="videos-link"
                      target="_blank"
                      className="btn-secondary"
                      rel="noopener noreferrer"
                    >
                      View Tutorials
                    </a>
                  </p>
                </div>
              ) : null}

              {screen === "templates" ? (
                <Templates setScreen={setScreen} props={props} />
              ) : null}
              {screen === "settings" ? (
                <Row>
                  <Col sm={12}>
                    <div className="home">
                      <h2>Settings</h2>
                      <div
                        onClick={() =>
                          props.props.history.push(
                            `/accounts/${props.accountId}/edit`
                          )
                        }
                        className="step"
                        style={{ borderTop: "1px solid #bdc3c7" }}
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#0176ff" />{" "}
                          Update LinkedIn password for {account.firstName}{" "}
                          {account.lastName}'s account
                        </p>
                      </div>
                      <div
                        onClick={() => setViewPaymentModal(true)}
                        className="step"
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#0176ff" />{" "}
                          Buy more accounts
                        </p>
                      </div>
                      <div
                        onClick={() => props.props.handleLogout()}
                        className="step"
                      >
                        <p>
                          <IosArrowForward fontSize="16px" color="#0176ff" />{" "}
                          Logout
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
        <Modal
          show={viewAddAaccountModal}
          onHide={() => setViewAddAccountModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <NewAccount
                changeScreen={changeScreen}
                history={props.props.history}
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewPaymentModal}
          onHide={() => setViewPaymentModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <Settings setScreen={setScreen} props={props} />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewCrmModal}
          onHide={() => setViewCrmModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center" style={{ padding: "20px" }}>
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Custom CRM Integration</b>
                  </p>

                  <p>Connect your CRM with all your LinkedIn accounts.</p>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <div className="s">
                    <a
                      style={{
                        margin: "auto",
                      }}
                      className="btn-secondary"
                      href="https://drift.me/davidhurley/meeting"
                    >
                      Schedule a call
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewReferFriend}
          onHide={() => setViewReferFriend(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center" style={{ padding: "20px" }}>
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Your Referral Link</b>
                  </p>
                  <p className="referLink">{`https://www.conversify.ai/r/${props.accountId}`}</p>
                  <p>
                    We'll refund you one month's payment for each customer you
                    refer.
                  </p>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewCampaignModal}
          onHide={() => setViewCampaignModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <ViewCampaign changeScreen={props.changeScreen} props={props} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return <div className="">{renderAccounts()}</div>;
}
